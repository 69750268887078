import React, { useState, useEffect } from 'react';
import { BarChart2, Calendar, Download, Share2, Filter, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { Select } from '../components/ui/Select';
import { Card } from '../components/ui/Card';
import { DataTable } from '../components/ui/DataTable';
import { supabase } from '../lib/supabase';
import { Input } from '../components/ui/Input';

// Analytics Components
import MetricsOverview from '../components/analytics/MetricsOverview';
import MarketTrends from '../components/analytics/MarketTrends';
import { CompetitorMap } from '../components/analytics/CompetitorMap';
import RevenueBreakdown from '../components/analytics/RevenueBreakdown';

interface Property {
  id: number;
  address: string;
  city: string;
  state: string;
  price: string;
  brand: string;
  size: number;
  fuelVolume: number;
  cstoreSales: number;
  customerCount: number;
  profits: number;
}

interface FilterState {
  brand: string;
  city: string;
  minSize: number;
  maxSize: number;
  minFuelVolume: number;
  maxFuelVolume: number;
  minCstoreSales: number;
  maxCstoreSales: number;
  similarityRange: number;
}

interface ComparisonMetrics {
  sizeDiff: number;
  fuelVolumeDiff: number;
  cstoreSalesDiff: number;
  overallSimilarity: number;
}

export default function Analytics() {
  const [properties, setProperties] = useState<Property[]>([]);
  const [referenceProperty, setReferenceProperty] = useState<Property | null>(null);
  const [filters, setFilters] = useState<FilterState>({
    brand: '',
    city: '',
    minSize: 0,
    maxSize: 0,
    minFuelVolume: 0,
    maxFuelVolume: 0,
    minCstoreSales: 0,
    maxCstoreSales: 0,
    similarityRange: 0.2,
  });

  useEffect(() => {
    fetchProperties();
  }, []);

  async function fetchProperties() {
    try {
      const { data, error } = await supabase
        .from('AnalyticsTable')
        .select(`
          ID,
          PropertyAddress,
          PropertyCity,
          PropertyState,
          SalePrice,
          Size,
          PropertyName,
          "2023FuelVolume",
          "2023Cstoresales",
          "2023grossprofit"
        `);
      
      if (error) throw error;
      
      // Transform the data to match Property type
      const transformedData = (data || []).map(analytics => ({
        id: analytics.ID,
        address: analytics.PropertyAddress || '',
        city: analytics.PropertyCity || '',
        state: analytics.PropertyState || '',
        price: analytics.SalePrice || '',
        brand: analytics.PropertyName || '',
        size: parseFloat((analytics.Size || '').replace(/,/g, '')) || 0,
        fuelVolume: parseFloat((analytics["2023FuelVolume"] || '').replace(/,/g, '')) || 0,
        cstoreSales: analytics["2023Cstoresales"] || 0,
        customerCount: 0, // Not available in current data
        profits: parseFloat((analytics["2023grossprofit"] || '').replace(/,/g, '')) || 0
      }));
      
      setProperties(transformedData);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  }

  const calculateSimilarity = (property: Property): ComparisonMetrics => {
    if (!referenceProperty) return {
      sizeDiff: 0,
      fuelVolumeDiff: 0,
      cstoreSalesDiff: 0,
      overallSimilarity: 0
    };

    const sizeDiff = Math.abs(property.size - referenceProperty.size) / referenceProperty.size;
    const fuelVolumeDiff = Math.abs(property.fuelVolume - referenceProperty.fuelVolume) / referenceProperty.fuelVolume;
    const cstoreSalesDiff = Math.abs(property.cstoreSales - referenceProperty.cstoreSales) / referenceProperty.cstoreSales;
    
    const overallSimilarity = 1 - ((sizeDiff + fuelVolumeDiff + cstoreSalesDiff) / 3);

    return {
      sizeDiff,
      fuelVolumeDiff,
      cstoreSalesDiff,
      overallSimilarity
    };
  };

  const filteredProperties = properties.filter(property => {
    if (property.id === referenceProperty?.id) return false;
    
    const similarity = calculateSimilarity(property);
    if (similarity.overallSimilarity < (1 - filters.similarityRange)) return false;
    
    if (filters.brand && property.brand !== filters.brand) return false;
    if (filters.city && property.city !== filters.city) return false;
    if (filters.minSize && property.size < filters.minSize) return false;
    if (filters.maxSize && property.size > filters.maxSize) return false;
    if (filters.minFuelVolume && property.fuelVolume < filters.minFuelVolume) return false;
    if (filters.maxFuelVolume && property.fuelVolume > filters.maxFuelVolume) return false;
    if (filters.minCstoreSales && property.cstoreSales < filters.minCstoreSales) return false;
    if (filters.maxCstoreSales && property.cstoreSales > filters.maxCstoreSales) return false;
    
    return true;
  });

  return (
    <div className="container mx-auto p-4 md:p-6 space-y-4 md:space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:mb-6">
        <h1 className="text-xl md:text-2xl font-bold">Gas Station Analytics</h1>
        <div className="flex flex-wrap gap-2 md:gap-4">
          <Button variant="outline" className="text-sm md:text-base">
            <Calendar className="w-4 h-4 mr-1 md:mr-2" />
            <span className="hidden sm:inline">Last 30 Days</span>
            <span className="sm:hidden">30d</span>
          </Button>
          <Button variant="outline" className="text-sm md:text-base">
            <Download className="w-4 h-4 mr-1 md:mr-2" />
            <span className="hidden sm:inline">Export</span>
            <span className="sm:hidden">Exp</span>
          </Button>
          <Button variant="outline" className="text-sm md:text-base">
            <Share2 className="w-4 h-4 mr-1 md:mr-2" />
            <span className="hidden sm:inline">Share</span>
            <span className="sm:hidden">Shr</span>
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6">
        <Card className="lg:col-span-1 p-4">
          <h2 className="text-base md:text-lg font-semibold mb-3 md:mb-4">Reference Property</h2>
          <Select
            label="Select Property"
            value={referenceProperty?.id.toString() || ''}
            onChange={(value) => {
              const selected = properties.find(p => p.id.toString() === value);
              setReferenceProperty(selected || null);
            }}
            options={properties.map(p => ({
              value: p.id.toString(),
              label: `${p.address}, ${p.city}`
            }))}
          />
          {referenceProperty && (
            <div className="mt-3 md:mt-4 space-y-2">
              <p className="text-sm md:text-base"><strong>Brand:</strong> {referenceProperty.brand}</p>
              <p className="text-sm md:text-base"><strong>Size:</strong> {referenceProperty.size.toLocaleString()} sq ft</p>
              <p className="text-sm md:text-base"><strong>Fuel Volume:</strong> {referenceProperty.fuelVolume.toLocaleString()} gal/month</p>
              <p className="text-sm md:text-base"><strong>C-Store Sales:</strong> ${referenceProperty.cstoreSales.toLocaleString()}</p>
            </div>
          )}
        </Card>

        <Card className="col-span-1 lg:col-span-2 overflow-x-auto">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 p-4">
            <h2 className="text-base md:text-lg font-semibold">Properties</h2>
            <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
              <Input
                placeholder="Search properties..."
                value={''}
                onChange={(e) => {}}
                className="w-full sm:w-[200px] text-sm"
              />
              <Button 
                variant="outline" 
                className="w-full sm:w-auto text-sm"
                onClick={() => {}}
              >
                Clear
              </Button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full min-w-[800px]">
              <thead>
                <tr className="border-b border-border">
                  <th className="p-3 text-left text-sm font-medium text-muted-foreground">Brand</th>
                  <th className="p-3 text-left text-sm font-medium text-muted-foreground">City</th>
                  <th className="p-3 text-right text-sm font-medium text-muted-foreground">Size (sq ft)</th>
                  <th className="p-3 text-right text-sm font-medium text-muted-foreground">Fuel Volume</th>
                  <th className="p-3 text-right text-sm font-medium text-muted-foreground">C-Store Sales</th>
                  <th className="p-3 text-right text-sm font-medium text-muted-foreground">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredProperties.map((property) => (
                  <tr
                    key={property.id}
                    className="border-b border-border transition-colors hover:bg-muted/50"
                  >
                    <td className="p-3 text-sm">{property.brand}</td>
                    <td className="p-3 text-sm">{property.city}</td>
                    <td className="p-3 text-sm text-right">
                      {property.size.toLocaleString()}
                    </td>
                    <td className="p-3 text-sm text-right">
                      {property.fuelVolume.toLocaleString()}
                    </td>
                    <td className="p-3 text-sm text-right">
                      {property.cstoreSales.toLocaleString()}
                    </td>
                    <td className="p-3 text-right">
                      <Button
                        variant="ghost"
                        size="sm"
                        className="text-xs"
                        onClick={() => {}}
                      >
                        Select
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </div>

      <MetricsOverview />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6">
        <MarketTrends />
        <RevenueBreakdown />
      </div>

      <CompetitorMap />
    </div>
  );
}