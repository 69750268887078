import React, { useState } from 'react';
import { MapPin, Navigation, Filter } from 'lucide-react';
import { Card } from '../ui/Card';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';

interface Competitor {
  id: string;
  name: string;
  distance: number;
  monthlyVolume: number;
  lat: number;
  lng: number;
}

export function CompetitorMap() {
  const [radius, setRadius] = useState("5");

  const competitors: Competitor[] = [
    {
      id: '1',
      name: 'City Gas Station',
      distance: 0.8,
      monthlyVolume: 120000,
      lat: 34.0522,
      lng: -118.2437
    },
    {
      id: '2',
      name: 'Highway Fuel Stop',
      distance: 1.2,
      monthlyVolume: 180000,
      lat: 34.0522,
      lng: -118.2437
    },
    {
      id: '3',
      name: 'Corner Gas & Market',
      distance: 1.5,
      monthlyVolume: 90000,
      lat: 34.0522,
      lng: -118.2437
    }
  ];

  return (
    <Card className="col-span-1 lg:col-span-2">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 p-4 border-b">
        <h2 className="text-base md:text-lg font-semibold">Competitor Map</h2>
        <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
          <Button variant="outline" size="sm" className="w-full sm:w-auto text-sm">
            <MapPin className="w-4 h-4 mr-2" />
            Center Map
          </Button>
          <Button variant="outline" size="sm" className="w-full sm:w-auto text-sm">
            <Filter className="w-4 h-4 mr-2" />
            Filter Competitors
          </Button>
        </div>
      </div>

      <div className="relative w-full" style={{ height: 'calc(100vh - 24rem)' }}>
        <div className="absolute inset-0 bg-muted animate-pulse flex items-center justify-center">
          <span className="text-sm text-muted-foreground">Loading map...</span>
        </div>
      </div>

      <div className="p-4 border-t">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
          <div className="text-sm text-muted-foreground">
            Showing <span className="font-medium">24</span> competitors within <span className="font-medium">{radius} miles</span>
          </div>
          <Select
            value={radius}
            onChange={setRadius}
            options={[
              { value: "1", label: "1 mile radius" },
              { value: "3", label: "3 mile radius" },
              { value: "5", label: "5 mile radius" },
              { value: "10", label: "10 mile radius" }
            ]}
            className="w-full sm:w-[180px] text-sm"
          />
        </div>
      </div>
    </Card>
  );
}